import { useContextHook } from "../../Context/ContextOPen";
import "./SideBar.css";
import { NavLink } from "react-router-dom";
import logo from "../../images/logo.jpg";

function SideBar() {
  const { openMenu, changeMenu } = useContextHook();
  const sidebar = [
    { name: "Home", link: "/Home" },
    { name: "About Us", link: "/About-Us" },
    { name: "Contact Us", link: "/Contact-Us" },
    { name: "features", link: "/features" },
    { name: "Home Text", link: "/home-text" },
    { name: "Our Business", link: "/our-business" },
    { name: "Message", link: "/message" },
    { name: "DATENSCHUTZ", link: "/protection" },
    { name: "Impressum", link: "/impressum" },
    { name: "Change Password", link: "/Change-Password" },
  ];
  return (
    <>
      <div
        className={`${openMenu ? "w-[360px]" : " w-[0px]"} ${
          openMenu
            ? " max-sm:translate-x-0 max-sm:w-3/4"
            : " max-sm:-translate-x-full"
        }  bg-Main sidebar h-[100vh] sideBar max-sm:h-[100%] sticky max-sm:fixed left-0 top-0 transition-all overflow-y-scroll  py-10 z-40`}
      >
        <div className="px-3">
          <ul className="text-black text-center font-semibold space-y-4">
            <li className="pt-10">
              <img src={logo} alt="" className="w-[200px] mx-auto rounded-xl" />
            </li>
            {sidebar.map((e) => (
              <li className="">
                <NavLink
                  to={e.link}
                  className="text-xl py-2 block rounded-3xl hover:text-Brown hover:text-Main hover:bg-white text-white"
                >
                  {e.name}
                </NavLink>
              </li>
            ))}
          </ul>
        </div>
      </div>
      <div
        onClick={changeMenu}
        style={{ transition: "0.4s" }}
        className={`${
          openMenu ? "" : "hidden"
        }  max-sm:w-1/4 h-full bg-black/20 fixed -right-0 top-0 transition-all z-30`}
      />
    </>
  );
}

export default SideBar;
