import { Col, Row } from "../../Grid-system";
import { Add, CardBox } from "../../components";
import "swiper/css";
import "swiper/css/pagination";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";
import { fileUrl, useFETCH } from "../../APIs/useMyAPI";
import Loading from "../../Tools/Loading";
function OurBusiness() {
  const { data, isLoading, deleteItem } = useFETCH(
    `business?local=none`,
    "business"
  );
  return (
    <div>
      {isLoading ? <Loading /> : ""}
      <Row>
        {data?.data.data.map((e) => (
          <Col lg={4} md={6} key={e.id}>
            <CardBox
              edit={`/our-business/${e.id}`}
              deleteClick={() => deleteItem(e)}
            >
              <Swiper
                pagination={true}
                modules={[Pagination]}
                className="mySwiper"
                style={{ direction: "ltr" }}
              >
                {e.images?.map((e) => (
                  <SwiperSlide>
                    <img
                      src={fileUrl + e.image}
                      alt=""
                      className="w-full h-[280px] object-fill"
                    />
                  </SwiperSlide>
                ))}
              </Swiper>
              <h2
                className={`font-bold text-2xl max-sm:text-lg text-center text-Third my-1 px-5`}
              >
                {e.title.en}
              </h2>
              <h2
                className={`font-bold text-2xl max-sm:text-lg text-center text-Third my-1 px-5`}
              >
                {e.title.ar}
              </h2>
              <p className="text-lg text-center font-semibold">
                {e.description.en}
              </p>
              <p className="text-lg text-center font-semibold">
                {e.description.ar}
              </p>
            </CardBox>
          </Col>
        ))}
      </Row>
      <Add link="/our-business/add" />
    </div>
  );
}
export default OurBusiness;
