import React, { useEffect, useState } from "react";
import { Col, Row } from "../../Grid-system";
import { Back, Buttons } from "../../components";
import { useContactUs, useFETCH } from "../../APIs/useMyAPI";
import Loading from "../../Tools/Loading";
const EditHomeText = () => {
  const { data } = useFETCH(`info?local=none`);
  const [homeUs, setHomeUs] = useState({
    en_title_home: "",
    ar_title_home: "",
    en_description_home: "",
    ar_description_home: "",
    en_title_footer: "",
    ar_title_footer: "",
    en_description_footer: "",
    ar_description_footer: "",
    en_location: "",
    ar_location: "",
  });
  useEffect(() => {
    setHomeUs({
      en_title_home: data?.data.data.en_title_home,
      ar_title_home: data?.data.data.ar_title_home,
      en_description_home: data?.data.data.en_description_home,
      ar_description_home: data?.data.data.ar_description_home,
      en_title_footer: data?.data.data.en_title_footer,
      ar_title_footer: data?.data.data.ar_title_footer,
      en_description_footer: data?.data.data.en_description_footer,
      ar_description_footer: data?.data.data.ar_description_footer,
      en_location: data?.data.data.en_location,
      ar_location: data?.data.data.ar_location,
    });
  }, [data?.data.data]);
  const { loading, updateContactUs } = useContactUs("info");
  return (
    <div>
      <Row className="items-center">
        <Col col={12} className="text-center font-semibold text-2xl">
          Home
        </Col>
        <Col md={5} className="mx-auto">
          <input
            type="text"
            name="text_en"
            value={homeUs?.en_title_home}
            onChange={(e) =>
              setHomeUs({ ...homeUs, en_title_home: e.target.value })
            }
            placeholder="العنوان بالألماني"
            className="border border-Secondary m-1 py-3 rounded-xl  w-full px-2 outline-none"
          />
        </Col>
        <Col md={5} className="mx-auto">
          <input
            type="text"
            style={{ direction: "rtl" }}
            name="text_ar"
            value={homeUs?.ar_title_home}
            onChange={(e) =>
              setHomeUs({ ...homeUs, ar_title_home: e.target.value })
            }
            placeholder="العنوان بالعربي"
            className="border border-Secondary m-1 py-3 rounded-xl  w-full px-2 outline-none "
          />
        </Col>
        <Col md={2}>
          <Buttons
            onClick={() =>
              updateContactUs(
                "title_home",
                homeUs.ar_title_home,
                homeUs.en_title_home
              )
            }
            name="حفظ"
            className={`bg-Secondary border-Secondary px-4 text-white hover:bg-opacity-75 `}
          />
        </Col>
        <Col md={5} className="mx-auto">
          <textarea
            type="text"
            name="text_en"
            value={homeUs?.en_description_home}
            onChange={(e) =>
              setHomeUs({ ...homeUs, en_description_home: e.target.value })
            }
            placeholder="الوصف بالألماني"
            className="border border-Secondary m-1 py-3 rounded-xl h-[200px] w-full px-2 outline-none"
          />
        </Col>
        <Col md={5} className="mx-auto">
          <textarea
            type="text"
            style={{ direction: "rtl" }}
            name="text_ar"
            value={homeUs?.ar_description_home}
            onChange={(e) =>
              setHomeUs({ ...homeUs, ar_description_home: e.target.value })
            }
            placeholder="الوصف بالعربي"
            className="border border-Secondary m-1 py-3 rounded-xl h-[200px] w-full px-2 outline-none "
          />
        </Col>
        <Col md={2}>
          <Buttons
            onClick={() =>
              updateContactUs(
                "description_home",
                homeUs.ar_description_home,
                homeUs.en_description_home
              )
            }
            name="حفظ"
            className={`bg-Secondary border-Secondary px-4 text-white hover:bg-opacity-75 `}
          />
        </Col>
        <Col col={12} className="text-center font-semibold text-2xl">
          Footer
        </Col>
        <Col md={5} className="mx-auto">
          <input
            type="text"
            name="text_en"
            value={homeUs?.en_title_footer}
            onChange={(e) =>
              setHomeUs({ ...homeUs, en_title_footer: e.target.value })
            }
            placeholder="العنوان بالألماني"
            className="border border-Secondary m-1 py-3 rounded-xl  w-full px-2 outline-none"
          />
        </Col>
        <Col md={5} className="mx-auto">
          <input
            type="text"
            style={{ direction: "rtl" }}
            name="text_ar"
            value={homeUs?.ar_title_footer}
            onChange={(e) =>
              setHomeUs({ ...homeUs, ar_title_footer: e.target.value })
            }
            placeholder="العنوان بالعربي"
            className="border border-Secondary m-1 py-3 rounded-xl  w-full px-2 outline-none "
          />
        </Col>
        <Col md={2}>
          <Buttons
            onClick={() =>
              updateContactUs(
                "title_footer",
                homeUs.ar_title_footer,
                homeUs.en_title_footer
              )
            }
            name="حفظ"
            className={`bg-Secondary border-Secondary px-4 text-white hover:bg-opacity-75 `}
          />
        </Col>
        <Col md={5} className="mx-auto">
          <textarea
            type="text"
            name="text_en"
            value={homeUs?.en_description_footer}
            onChange={(e) =>
              setHomeUs({ ...homeUs, en_description_footer: e.target.value })
            }
            placeholder="الوصف بالألماني"
            className="border border-Secondary m-1 py-3 rounded-xl h-[200px] w-full px-2 outline-none"
          />
        </Col>
        <Col md={5} className="mx-auto">
          <textarea
            type="text"
            style={{ direction: "rtl" }}
            name="text_ar"
            value={homeUs?.ar_description_footer}
            onChange={(e) =>
              setHomeUs({ ...homeUs, ar_description_footer: e.target.value })
            }
            placeholder="الوصف بالعربي"
            className="border border-Secondary m-1 py-3 rounded-xl h-[200px] w-full px-2 outline-none "
          />
        </Col>
        <Col md={2}>
          <Buttons
            onClick={() =>
              updateContactUs(
                "description_footer",
                homeUs.ar_description_footer,
                homeUs.en_description_footer
              )
            }
            name="حفظ"
            className={`bg-Secondary border-Secondary px-4 text-white hover:bg-opacity-75 `}
          />
        </Col>
        <Col col={12} className="text-center font-semibold text-2xl">
          Location
        </Col>
        <Col md={5} className="mx-auto">
          <input
            type="text"
            name="text_en"
            value={homeUs?.en_location}
            onChange={(e) =>
              setHomeUs({ ...homeUs, en_location: e.target.value })
            }
            placeholder="العنوان بالألماني"
            className="border border-Secondary m-1 py-3 rounded-xl  w-full px-2 outline-none"
          />
        </Col>
        <Col md={5} className="mx-auto">
          <input
            type="text"
            style={{ direction: "rtl" }}
            name="text_ar"
            value={homeUs?.ar_location}
            onChange={(e) =>
              setHomeUs({ ...homeUs, ar_location: e.target.value })
            }
            placeholder="العنوان بالعربي"
            className="border border-Secondary m-1 py-3 rounded-xl  w-full px-2 outline-none "
          />
        </Col>
        <Col md={2}>
          <Buttons
            onClick={() =>
              updateContactUs(
                "location",
                homeUs.ar_location,
                homeUs.en_location
              )
            }
            name="حفظ"
            className={`bg-Secondary border-Secondary px-4 text-white hover:bg-opacity-75 `}
          />
        </Col>
        {loading ? <Loading /> : ""}
      </Row>
      <Back />
    </div>
  );
};

export default EditHomeText;
