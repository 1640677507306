import { ButtonRed, CardBox, Title } from "../../components";
import { Col, Row } from "../../Grid-system";
import { fileUrl, useFETCH } from "../../APIs/useMyAPI";
import Loading from "../../Tools/Loading";

const AboutUs = () => {
  const { data, deleteItem, isLoading } = useFETCH(
    `home-images?type=about_images`,
    "home-images"
  );
  const { data: dataAbout } = useFETCH(`info?local=none`);
  return (
    <div className="py-5">
      <Title title="About us" />
      {isLoading ? <Loading /> : ""}
      <Row>
        <Col md={6} className="mx-auto">
          <CardBox showDelete edit="/About-Us/edit">
            <h1 className="font-semibold">
              {dataAbout?.data.data.en_title_about}
            </h1>
            <h1 className="font-semibold">
              {dataAbout?.data.data.ar_title_about}
            </h1>
            <p>{dataAbout?.data.data.en_description_about}</p>
            <p> {dataAbout?.data.data.ar_description_about}</p>
          </CardBox>
        </Col>
      </Row>
      <Row>
        {data?.data.data.map((e) => (
          <Col lg={3} md={4} xs={6} key={e.id}>
            <CardBox deleteClick={() => deleteItem(e)}>
              <img
                src={fileUrl + e.image}
                alt=""
                className="w-full h-[150px]"
              />
            </CardBox>
          </Col>
        ))}
      </Row>
      <div className="fixed bottom-10 right-10">
        <ButtonRed
          link="/About-Us/add"
          name="add image"
          className="px-7 py-2"
        />
      </div>
    </div>
  );
};

export default AboutUs;
