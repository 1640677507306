import React, { useEffect, useState } from "react";
import { Col, Row } from "../../Grid-system";
import { Back, Buttons } from "../../components";
import { useContactUs, useFETCH } from "../../APIs/useMyAPI";
import Loading from "../../Tools/Loading";
const EditTextAboutUs = () => {
  const { data } = useFETCH(`info?local=none`);
  const [aboutUs, setAboutUs] = useState({
    en_title_about: "",
    ar_title_about: "",
    en_description_about: "",
    ar_description_about: "",
  });
  useEffect(() => {
    setAboutUs({
      en_title_about: data?.data.data.en_title_about,
      ar_title_about: data?.data.data.ar_title_about,
      en_description_about: data?.data.data.en_description_about,
      ar_description_about: data?.data.data.ar_description_about,
    });
  }, [data?.data.data]);
  const { loading, updateContactUs } = useContactUs("info");
  return (
    <div>
      <Row className="items-center">
        <Col md={5} className="mx-auto">
          <input
            type="text"
            name="text_en"
            value={aboutUs?.en_title_about}
            onChange={(e) =>
              setAboutUs({ ...aboutUs, en_title_about: e.target.value })
            }
            placeholder="العنون بالألماني"
            className="border border-Secondary m-1 py-3 rounded-xl  w-full px-2 outline-none"
          />
        </Col>
        <Col md={5} className="mx-auto">
          <input
            type="text"
            style={{ direction: "rtl" }}
            name="text_ar"
            value={aboutUs?.ar_title_about}
            onChange={(e) =>
              setAboutUs({ ...aboutUs, ar_title_about: e.target.value })
            }
            placeholder="العنون بالعربي"
            className="border border-Secondary m-1 py-3 rounded-xl  w-full px-2 outline-none "
          />
        </Col>
        <Col md={2}>
          <Buttons
            onClick={() =>
              updateContactUs(
                "title_about",
                aboutUs.ar_title_about,
                aboutUs.en_title_about
              )
            }
            name="حفظ"
            className={`bg-Secondary border-Secondary px-4 text-white hover:bg-opacity-75 `}
          />
        </Col>
        <Col md={5} className="mx-auto">
          <textarea
            type="text"
            name="text_en"
            value={aboutUs?.en_description_about}
            onChange={(e) =>
              setAboutUs({ ...aboutUs, en_description_about: e.target.value })
            }
            placeholder="الوصف بالألماني"
            className="border border-Secondary m-1 py-3 rounded-xl h-[200px] w-full px-2 outline-none"
          />
        </Col>
        <Col md={5} className="mx-auto">
          <textarea
            type="text"
            style={{ direction: "rtl" }}
            name="text_ar"
            value={aboutUs?.ar_description_about}
            onChange={(e) =>
              setAboutUs({ ...aboutUs, ar_description_about: e.target.value })
            }
            placeholder="الوصف بالعربي"
            className="border border-Secondary m-1 py-3 rounded-xl h-[200px] w-full px-2 outline-none "
          />
        </Col>
        <Col md={2}>
          <Buttons
            onClick={() =>
              updateContactUs(
                "description_about",
                aboutUs.ar_description_about,
                aboutUs.en_description_about
              )
            }
            name="حفظ"
            className={`bg-Secondary border-Secondary px-4 text-white hover:bg-opacity-75 `}
          />
        </Col>
        {loading ? <Loading /> : ""}
      </Row>
      <Back />
    </div>
  );
};

export default EditTextAboutUs;
