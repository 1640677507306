import { useFETCH } from "../../APIs/useMyAPI";
import { Col, Row } from "../../Grid-system";
import Loading from "../../Tools/Loading";
import { Add, CardBox } from "../../components";

function Features({ api }) {
  const { data, isLoading, deleteItem } = useFETCH(`${api}?local=none`, api);
  return (
    <>
      {isLoading ? <Loading /> : ""}
      <Row>
        {data?.data.data.map((e) => (
          <Col lg={6} key={e.id}>
            <CardBox
              edit={`/features/${e.id}`}
              deleteClick={() => deleteItem(e)}
            >
              <p className="font-bold text-2xl text-center my-3">
                {e.title.en}
              </p>
              <p className="font-bold text-2xl text-center my-3">
                {e.title.ar}
              </p>
              <p className="text-lg text-center text-gray-600">
                {e.description.en}
              </p>
              <p className="text-lg text-center text-gray-600">
                {e.description.ar}
              </p>
            </CardBox>
          </Col>
        ))}
        {data?.data.data == "" && (
          <p className="text-3xl text-center font-bold h-[70vh] flex justify-center items-center">
            There are no items to show
          </p>
        )}
      </Row>
      <Add link={`/${api}/add`} />
    </>
  );
}
export default Features;
