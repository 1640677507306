import { MdOutlineMailOutline } from "react-icons/md";
import { AiOutlineWhatsApp, AiTwotonePhone } from "react-icons/ai";
import { FaFacebook, FaInstagram } from "react-icons/fa";
import { IoLocationSharp } from "react-icons/io5";
import { ButtonRed, Title } from "../../components";
import { Col, Row } from "../../Grid-system";
import { useFETCH } from "../../APIs/useMyAPI";
import Loading from "../../Tools/Loading";

const ContactUs = () => {
  const { data, isLoading } = useFETCH(`info?local=en`);
  let dataAll = data?.data.data;
  return (
    <div>
      <Title title="Contact Us " />
      {isLoading ? <Loading /> : ""}
      <Row justify="center">
        <Col md={6}>
          <div className="overflow-hidden flex-wrap flex items-center justify-between border-[3px] border-Main rounded-xl shadow-md font-semibold text-xl py-5 px-3 bg-white">
            <FaInstagram size={60} className="" color="#CB3878" />
            <p className="font-semibold px-2 text-Gray text-xl">
              {dataAll?.instagram}
            </p>
          </div>
        </Col>
        <Col md={6}>
          <div className="overflow-hidden flex-wrap flex items-center justify-between border-[3px] border-Main rounded-xl shadow-md font-semibold text-xl py-5 px-3 bg-white">
            <FaFacebook size={60} className="" color="#3B5998" />
            <p className="font-semibold px-2 text-Gray text-xl">
              {dataAll?.facebook}
            </p>
          </div>
        </Col>

        <Col md={6}>
          <div className="overflow-hidden flex-wrap flex items-center justify-between border-[3px] border-Main rounded-xl shadow-md font-semibold text-xl py-5 px-3 bg-white">
            <AiOutlineWhatsApp size={60} className="" color="#0DC143" />
            <p className="font-semibold px-2 text-Gray text-xl">
              {dataAll?.whatsapp}
            </p>
          </div>
        </Col>

        <Col md={6}>
          <div className="overflow-hidden flex-wrap flex items-center justify-between border-[3px] border-Main rounded-xl shadow-md font-semibold text-xl py-5 px-3 bg-white">
            <MdOutlineMailOutline size={60} className="" color="#707070" />
            <p className="font-semibold px-2 text-Gray text-xl">
              {dataAll?.email}
            </p>
          </div>
        </Col>

        <Col md={6}>
          <div className="overflow-hidden flex-wrap flex items-center justify-between border-[3px] border-Main rounded-xl shadow-md font-semibold text-xl py-5 px-3 bg-white">
            <AiTwotonePhone size={60} className="" color="#FEDADA" />
            <p className="font-semibold px-2 text-Gray text-xl">
              {dataAll?.number_one}
            </p>
          </div>
        </Col>
        <Col md={6}>
          <div className="overflow-hidden flex-wrap flex items-center justify-between border-[3px] border-Main rounded-xl shadow-md font-semibold text-xl py-5 px-3 bg-white">
            <AiTwotonePhone size={60} className="" color="#9F7CC6" />
            <p className="font-semibold px-2 text-Gray text-xl">
              {dataAll?.number_two}
            </p>
          </div>
        </Col>
      </Row>
      <div className="fixed bottom-10 right-10">
        <ButtonRed link="/Contact-Us/edit" name="Edit" className="px-7 py-2" />
      </div>
    </div>
  );
};

export default ContactUs;
