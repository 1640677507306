import { useState } from "react";
import { Link } from "react-router-dom";
import { BiShow } from "react-icons/bi";
import Buttons from "../Buttons/Buttons";
import { RiDeleteBin5Line, RiEdit2Fill } from "react-icons/ri";

const CardBox = ({
  children,
  deleteClick,
  edit,
  className,
  showEdit,
  show,
  showDelete,
}) => {
  const [sure, setSure] = useState(false);

  return (
    <>
      {sure && (
        <>
          <div
            onClick={() => setSure(false)}
            className={`fixed w-full h-full top-0 left-0 bg-black/50 z-40 flex justify-center items-center`}
          ></div>
          <div className="fixed -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2 bg-white z-50 rounded-3xl w-[500px] max-w-[500px] min-h-[200px] ">
            <p className="font-semibold text-3xl text-center py-7">
              Are you sure to delete the item ?
            </p>
            <div className="flex items-end m-5 space-x-2">
              <Buttons
                onClick={deleteClick}
                name="Yes"
                className="py-3 px-16 hover:text-white hover:bg-Main"
              />
              <Buttons
                onClick={() => setSure(false)}
                name="Cancel"
                className="py-3 px-16 hover:text-white hover:bg-Main"
              />
            </div>
          </div>
        </>
      )}
      {/* </div> */}

      <div
        className={`border-2 border-Secondary rounded-2xl p-3 bgimg w-full h-full ${className}`}
      >
        <div className="flex justify-end gap-1 mb-1">
          <Link to={show || ""} className={`${show ? "" : "hidden"}`}>
            <div className=" w-10 h-10 rounded-full hover:bg-slate-200 flex cursor-pointer justify-center items-center">
              <BiShow size={25} className="text-green-700" />
            </div>
          </Link>
          <div
            className={`${
              showDelete
                ? "hidden"
                : "w-10 h-10 rounded-full hover:bg-slate-200 flex cursor-pointer justify-center items-center"
            }`}
          >
            <RiDeleteBin5Line
              size={30}
              className="text-red-600"
              onClick={() => setSure(true)}
            />
          </div>
          {edit && (
            <div
              className={`${
                showEdit ? "hidden" : ""
              } w-10 h-10 rounded-full  flex justify-center items-center cursor-pointer hover:bg-slate-200`}
            >
              <Link to={edit || ""}>
                <RiEdit2Fill size={30} className="cursor-pointer text-Main" />
              </Link>
            </div>
          )}
        </div>
        <div className=" overflow-hidden">{children}</div>
      </div>
    </>
  );
};

export default CardBox;
