import { Add, Buttons, CardBox, Title } from "../../components";
import { Col, Row } from "../../Grid-system";
import { fileUrl, useFETCH } from "../../APIs/useMyAPI";
import Loading from "../../Tools/Loading";
import { Link } from "react-router-dom";

const ImageSlider = () => {
  const { data, deleteItem, isLoading } = useFETCH(
    `home-images?type=main_images`,
    "home-images"
  );
  const { data: datas, deleteItem: deleteItems } = useFETCH(
    `home-images?type=home_images`,
    "home-images"
  );
  const { data: dataHome } = useFETCH(`info?local=none`);
  return (
    <div>
      <Title title="Images" />
      {isLoading ? <Loading /> : ""}
      <Row>
        <Col md={6} className="mx-auto">
          <CardBox showDelete edit="/home/edit">
            <h1 className="text-center font-semibold text-2xl">Home</h1>
            <h1 className="font-semibold">
              {dataHome?.data.data.en_title_home}
            </h1>
            <h1 className="font-semibold">
              {dataHome?.data.data.ar_title_home}
            </h1>
            <p>{dataHome?.data.data.en_description_home}</p>
            <p> {dataHome?.data.data.ar_description_home}</p>
          </CardBox>
        </Col>
        <Col md={6} className="mx-auto">
          <CardBox showDelete edit="/home/edit">
            <h1 className="text-center font-semibold text-2xl">Footer</h1>
            <h1 className="font-semibold">
              {dataHome?.data.data.en_title_footer}
            </h1>
            <h1 className="font-semibold">
              {dataHome?.data.data.ar_title_footer}
            </h1>
            <p>{dataHome?.data.data.en_description_footer}</p>
            <p> {dataHome?.data.data.ar_description_footer}</p>
          </CardBox>
        </Col>
        <Col md={6} className="mx-auto">
          <CardBox showDelete edit="/home/edit">
            <h1 className="text-center font-semibold text-2xl">Location</h1>
            <h1 className="font-semibold">{dataHome?.data.data.en_location}</h1>
            <h1 className="font-semibold">{dataHome?.data.data.ar_location}</h1>
          </CardBox>
        </Col>
      </Row>
      <Row>
        {data?.data.data.map((e) => (
          <Col lg={3} md={4} xs={6} key={e.id}>
            <CardBox deleteClick={() => deleteItem(e)}>
              <img
                src={fileUrl + e.image}
                alt=""
                className="w-full h-[150px]"
              />
            </CardBox>
          </Col>
        ))}
      </Row>

      <Link to="/home/add">
        <div
          className={`w-[250px] my-8 rounded-lg py-2 text-center bg-Secondary border-Secondary px-4 text-white hover:bg-opacity-75 `}
        >
          اضف صورة السلايدر الرئيسي
        </div>
      </Link>
      <Row>
        {datas?.data.data.map((e) => (
          <Col lg={3} md={4} xs={6} key={e.id}>
            <CardBox deleteClick={() => deleteItem(e)}>
              <img
                src={fileUrl + e.image}
                alt=""
                className="w-full h-[150px]"
              />
            </CardBox>
          </Col>
        ))}
      </Row>

      <Link to="/home/add-image">
        <div
          className={`w-[320px] my-8 rounded-lg py-2 text-center bg-Secondary border-Secondary px-4 text-white hover:bg-opacity-75 `}
        >
          اضف صورة السلايدر في الصفحة الرئيسية
        </div>
      </Link>
      {/* <Add link="/home/add-image" /> */}
    </div>
  );
};

export default ImageSlider;
