function Textarea({ type, name, value, onChange, placeholder, className }) {
  return (
    <div>
      <textarea
        type={type || "text"}
        name={name}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        className={`border border-Secondary m-1 py-3 rounded-xl h-[200px] w-full px-2 outline-none ${className}`}
      />
    </div>
  );
}
export default Textarea;
