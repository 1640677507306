import { useEffect } from "react";
import { Col, Row } from "../../Grid-system";
import { Back, Input, Textarea } from "../../components";
import { useFETCH, usePOST } from "../../APIs/useMyAPI";
import Loading from "../../Tools/Loading";
import { useParams } from "react-router-dom";
const EditFeatures = ({ api }) => {
  const { id } = useParams();
  const { handleChangeInput, handleSubmit, setFormData, formData, loading } =
    usePOST({});
  const { data } = useFETCH(`${api}/${id}?local=none`);
  const handleSubmitMain = (e) => {
    e.preventDefault();
    handleSubmit(id === "add" ? api : `${api}/${id}`);
  };
  let dataOld = data?.data.data;
  useEffect(() => {
    id !== "add" &&
      setFormData({
        en_title: dataOld?.title.en,
        ar_title: dataOld?.title.ar,
        en_description: dataOld?.description.en,
        ar_description: dataOld?.description.ar,
      });
  }, [dataOld]);
  return (
    <div>
      <Row>
        <Col md={7} className="mx-auto">
          <Input
            type="text"
            name="ar_title"
            value={formData?.ar_title}
            onChange={handleChangeInput}
            placeholder="الاسم بالعربي"
            className="text-end"
            classNameTitle="text-end"
          />
        </Col>
        <Col md={7} className="mx-auto">
          <Input
            type="text"
            name="en_title"
            value={formData?.en_title}
            onChange={handleChangeInput}
            placeholder="الاسم بالألماني"
            classNameTitle="text-end"
          />
        </Col>
        <Col md={7} className="mx-auto">
          <Textarea
            type="text"
            name="ar_description"
            value={formData?.ar_description}
            onChange={handleChangeInput}
            placeholder="الوصف بالعربي"
            className="text-end"
          />
        </Col>
        <Col md={7} className="mx-auto">
          <Textarea
            type="text"
            name="en_description"
            value={formData?.en_description}
            onChange={handleChangeInput}
            placeholder="الوصف بالألماني"
          />
        </Col>
        {loading ? <Loading /> : ""}
      </Row>
      <Back onClick={handleSubmitMain} name="Save" />
    </div>
  );
};

export default EditFeatures;
