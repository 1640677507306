import { MdOutlineMailOutline } from "react-icons/md";
import { BiSave } from "react-icons/bi";
import { AiOutlineWhatsApp, AiTwotonePhone } from "react-icons/ai";
import { FaFacebook, FaInstagram } from "react-icons/fa";
import { Col, Row } from "../../Grid-system";
import { Back } from "../../components";
import { useContactUs, useFETCH } from "../../APIs/useMyAPI";
import { useEffect, useState } from "react";
import Loading from "../../Tools/Loading";

const UpditeContact = () => {
  const [formDataContact, setFormDataContact] = useState({});
  const { loading, updateContactUs } = useContactUs("info");
  const { data } = useFETCH(`info?local=en`);
  let dataOld = data?.data.data;
  useEffect(() => {
    setFormDataContact({
      instagram: dataOld?.instagram,
      facebook: dataOld?.facebook,
      whatsapp: dataOld?.whatsapp,
      email: dataOld?.email,
      number_one: dataOld?.number_one,
      number_two: dataOld?.number_two,
    });
  }, [dataOld]);
  return (
    <>
      <Row justify="center" gap={5}>
        <Col md={6}>
          <div className="border-4 border-Main rounded-xl flex flex-between p-4 items-center relative bg-white">
            <FaInstagram size={60} color="#CB3878" />
            <input
              value={formDataContact?.instagram}
              onChange={(e) =>
                setFormDataContact({
                  ...formDataContact,
                  instagram: e.target.value,
                })
              }
              placeholder="https://url...."
              className="text-xl font-semibold px-2 outline-none py-5 w-[100%]"
            />
            {loading ? <Loading /> : ""}
            <div className="">
              <BiSave
                onClick={() =>
                  updateContactUs(
                    "instagram",
                    formDataContact.instagram,
                    formDataContact.instagram
                  )
                }
                color="#3B5998 "
                className="mx-1 cursor-pointer"
                size={35}
              />
            </div>
          </div>
        </Col>
        <Col md={6}>
          <div className="border-4 border-Main rounded-xl flex flex-between p-4 items-center relative bg-white">
            <FaFacebook size={60} color="#3B5998" />
            <input
              value={formDataContact?.facebook}
              onChange={(e) =>
                setFormDataContact({
                  ...formDataContact,
                  facebook: e.target.value,
                })
              }
              placeholder="https://url...."
              className="text-xl font-semibold px-2 outline-none py-5 w-[100%]"
            />
            <div className="">
              <BiSave
                onClick={() =>
                  updateContactUs(
                    "facebook",
                    formDataContact.facebook,
                    formDataContact.facebook
                  )
                }
                color="#3B5998 "
                className="mx-1 cursor-pointer"
                size={35}
              />
            </div>
          </div>
        </Col>
        <Col md={6}>
          <div className="border-4 border-Main rounded-xl flex flex-between p-4 items-center relative bg-white">
            <AiOutlineWhatsApp size={60} color="#0DC143" />
            <input
              value={formDataContact?.whatsapp}
              onChange={(e) =>
                setFormDataContact({
                  ...formDataContact,
                  whatsapp: e.target.value,
                })
              }
              placeholder="whatsapp number"
              className="text-xl font-semibold px-2 outline-none py-5 w-[100%]"
            />
            <div className="">
              <BiSave
                onClick={() =>
                  updateContactUs(
                    "whatsapp",
                    formDataContact.whatsapp,
                    formDataContact.whatsapp
                  )
                }
                color="#3B5998 "
                className="mx-1 cursor-pointer"
                size={35}
              />
            </div>
          </div>
        </Col>
        <Col md={6}>
          <div className="border-4 border-Main rounded-xl flex flex-between p-4 items-center relative bg-white">
            <MdOutlineMailOutline size={60} color="#707070" />
            <input
              value={formDataContact?.email}
              onChange={(e) =>
                setFormDataContact({
                  ...formDataContact,
                  email: e.target.value,
                })
              }
              placeholder="https://url...."
              className="text-xl font-semibold px-2 outline-none py-5 w-[100%]"
            />
            <div className="">
              <BiSave
                onClick={() =>
                  updateContactUs(
                    "email",
                    formDataContact.email,
                    formDataContact.email
                  )
                }
                color="#3B5998 "
                className="mx-1 cursor-pointer"
                size={35}
              />
            </div>
          </div>
        </Col>
        <Col md={6}>
          <div className="border-4 border-Main rounded-xl flex flex-between p-4 items-center relative bg-white">
            <AiTwotonePhone size={60} color="#FEDADA" />
            <input
              value={formDataContact?.number_one}
              onChange={(e) =>
                setFormDataContact({
                  ...formDataContact,
                  number_one: e.target.value,
                })
              }
              placeholder="12356..."
              className="text-xl font-semibold px-2 outline-none py-5 w-[100%]"
            />
            <div className="">
              <BiSave
                onClick={() =>
                  updateContactUs(
                    "number_one",
                    formDataContact.number_one,
                    formDataContact.number_one
                  )
                }
                color="#3B5998 "
                className="mx-1 cursor-pointer"
                size={35}
              />
            </div>
          </div>
        </Col>
        <Col md={6}>
          <div className="border-4 border-Main rounded-xl flex flex-between p-4 items-center relative bg-white">
            <AiTwotonePhone size={60} color="#9F7CC6" />
            <input
              placeholder="12356..."
              value={formDataContact?.number_two}
              onChange={(e) =>
                setFormDataContact({
                  ...formDataContact,
                  number_two: e.target.value,
                })
              }
              className="text-xl font-semibold px-2 outline-none py-5 w-[100%]"
            />
            <div className="">
              <BiSave
                onClick={() =>
                  updateContactUs(
                    "number_two",
                    formDataContact.number_two,
                    formDataContact.number_two
                  )
                }
                color="#3B5998 "
                className="mx-1 cursor-pointer"
                size={35}
              />
            </div>
          </div>
        </Col>
      </Row>
      <Back />
    </>
  );
};

export default UpditeContact;
