const Input = ({
  type,
  name,
  value,
  onChange,
  placeholder,
  className,
  classNameTitle,
}) => {
  return (
    <>
      <h1 className={`${classNameTitle}`}>{placeholder}</h1>
      <input
        type={type || "text"}
        name={name}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        className={`border border-Secondary m-1 py-3 rounded-xl ${className}`}
      />
    </>
  );
};
export default Input;
