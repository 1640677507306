import {
  AboutUs,
  AddHomeImage,
  AddImageSlider,
  ChangePassword,
  ContactUs,
  EditAboutUs,
  EditFeatures,
  EditHomeText,
  EditOurBusiness,
  EditTextAboutUs,
  Features,
  ImageSlider,
  Impressum,
  Login,
  Message,
  OurBusiness,
  UpditeContact,
} from "./pages/index";
import { Navbar, SideBar } from "./components/index";
import { Route, Routes } from "react-router-dom";
import Logo from "./images/logo.jpg";
import { RequireAuth, useFETCH } from "./APIs/useMyAPI";
import { Container } from "./Grid-system";
import { P403, P404, P500 } from "./Tools/Error";

const App = () => {
  const { data } = useFETCH("auth/check");
  return (
    <>
      <div className="relative flex">
        <SideBar />
        <div className="container mx-auto relative font-semibold">
          <Navbar />
          <Container>
            <Routes>
              <Route path="login">
                <Route index element={<Login />} />
              </Route>
              <Route element={<RequireAuth />}>
                <Route
                  path=""
                  element={
                    <h1 className="grid place-content-center h-[60vh]">
                      <img src={Logo} alt="" className="" />
                    </h1>
                  }
                />
                <Route path="*" element={<P404 />} />
                <Route path="403" element={<P403 />} />
                <Route path="500" element={<P500 />} />
                <Route path="impressum" element={<Impressum />} />
                <Route path="home">
                  <Route index element={<ImageSlider />} />
                  <Route path="add" element={<AddImageSlider />} />
                  <Route path="add-image" element={<AddHomeImage />} />
                  <Route path=":id" element={<EditHomeText />} />
                </Route>
                <Route path="About-Us">
                  <Route index element={<AboutUs />} />
                  <Route path="add" element={<EditAboutUs />} />
                  <Route path=":id" element={<EditTextAboutUs />} />
                </Route>
                <Route path="Contact-Us">
                  <Route index element={<ContactUs />} />
                  <Route path=":id" element={<UpditeContact />} />
                </Route>
                <Route path="features">
                  <Route index element={<Features api="features" />} />
                  <Route path=":id" element={<EditFeatures api="features" />} />
                </Route>
                <Route path="protection">
                  <Route index element={<Features api="protection" />} />
                  <Route
                    path=":id"
                    element={<EditFeatures api="protection" />}
                  />
                </Route>
                <Route path="home-text">
                  <Route index element={<Features api="home-text" />} />
                  <Route
                    path=":id"
                    element={<EditFeatures api="home-text" />}
                  />
                </Route>
                <Route path="our-business">
                  <Route index element={<OurBusiness />} />
                  <Route path=":id" element={<EditOurBusiness />} />
                </Route>
                <Route path="message" element={<Message />} />
                <Route path="Change-Password" element={<ChangePassword />} />
              </Route>
            </Routes>
          </Container>
        </div>
      </div>
    </>
  );
};

export default App;
