import React from "react";
import { CardBox, Data, Title } from "../../components";
import { Col, Row } from "../../Grid-system";
import { useFETCH } from "../../APIs/useMyAPI";
import Loading from "../../Tools/Loading";

const Message = () => {
  const { data, isLoading, deleteItem } = useFETCH(`messages`);
  return (
    <>
      <Title title="Messages" />
      <Row>
        {isLoading ? <Loading /> : ""}
        {data?.data.data.map((e) => (
          <Col lg={4} md={6}>
            <CardBox deleteClick={() => deleteItem(e)} showEdit={true}>
              <Data name="Name" text={e.name} />
              <Data name="Email" text={e.email} />
              <Data name="Phone" text={e.phone_number} />
              <Data name="Home Location" text={e.location} />
              <Data name="Street Name" text={e.street_name} />
              <Data name="Home Distance" text={e.house_area} />
              <Data name="Home Number" text={e.house_number} />
              <Data name="Message" text={e.message} />
              <Data name="Date" text={e.created_at} />
            </CardBox>
          </Col>
        ))}
      </Row>
    </>
  );
};

export default Message;
