import { useEffect, useState } from "react";
import { Col, Row } from "../../Grid-system";
import { Back, Input } from "../../components";
import { fileUrl, useFETCH, usePOST } from "../../APIs/useMyAPI";
import Loading from "../../Tools/Loading";
import AddImage from "../../Tools/AddFile";
import { useParams } from "react-router-dom";
const EditOurBusiness = () => {
  const { id } = useParams();
  const [deleteImgs, setDeleteImgs] = useState(false);
  const {
    handleChangeInput,
    handleChangeArrayImages,
    handleCheckedArray,
    handleSubmit,
    viewImages,
    setViewImages,
    images,
    setImages,
    setFormData,
    formData,
    loading,
  } = usePOST({});
  const { data } = useFETCH(`business/${id}?local=none`);
  const handleSubmitMain = (e) => {
    e.preventDefault();
    handleSubmit(id === "add" ? "business" : `business/${id}`);
  };
  let dataOld = data?.data.data;
  useEffect(() => {
    id !== "add" &&
      setFormData({
        en_title: dataOld?.title?.en,
        ar_title: dataOld?.title?.ar,
        en_description: dataOld?.description.en,
        ar_description: dataOld?.description.ar,
      });
  }, [dataOld]);
  return (
    <div>
      <Row>
        <Col md={7} className="mx-auto">
          <Input
            type="text"
            name="ar_title"
            value={formData?.ar_title}
            onChange={handleChangeInput}
            placeholder="الاسم بالعربي"
            className="text-end"
            classNameTitle="text-end"
          />
        </Col>
        <Col md={7} className="mx-auto">
          <Input
            type="text"
            name="en_title"
            value={formData?.en_title}
            onChange={handleChangeInput}
            placeholder="الاسم بالألماني"
            classNameTitle="text-end"
          />
        </Col>
        <Col md={7} className="mx-auto">
          <textarea
            type="text"
            name="ar_description"
            value={formData?.ar_description}
            onChange={handleChangeInput}
            placeholder="الوصف بالعربي"
            className="border border-Secondary m-1 py-3 rounded-xl h-[200px] w-full px-2 outline-none text-end"
          />
        </Col>
        <Col md={7} className="mx-auto">
          <textarea
            type="text"
            name="en_description"
            value={formData?.en_description}
            onChange={handleChangeInput}
            placeholder="الوصف بالألماني"
            className="border border-Secondary m-1 py-3 rounded-xl h-[200px] w-full px-2 outline-none"
          />
        </Col>
        <Col md={7} className="mx-auto">
          <AddImage
            type="IMAGES"
            name="images[]"
            onChange={handleChangeArrayImages}
          />
          <Row className="py-4">
            {viewImages["images[]"] &&
              viewImages["images[]"]?.map((e, i) => (
                <Col md={3} xs={4} sm={6} lg={24}>
                  <div className="relative">
                    <span
                      onClick={() => {
                        let total = [...viewImages["images[]"]];
                        let total2 = [...images["images[]"]];
                        total.splice(i, 1);
                        total2.splice(i, 1);
                        setViewImages({
                          ...viewImages["images[]"],
                          "images[]": total,
                        });
                        setImages({
                          ...images["images[]"],
                          "images[]": total2,
                        });
                      }}
                      className="bg-red-700 w-6 h-6 flex justify-center items-center font-bold text-white cursor-pointer z-10 -top-2 rounded-full absolute"
                    >
                      X
                    </span>
                    <img src={e} alt="" className="w-full rounded-2xl" />
                  </div>
                </Col>
              ))}
          </Row>
          {id !== "add" && (
            <div>
              <div
                onClick={() => setDeleteImgs(!deleteImgs)}
                className="cursor-pointer px-5 py-2 border border-Main w-fit rounded-lg text-lg text-white font-semibold bg-Main my-2 ml-auto "
              >
                حذف الصور
              </div>
              <Row>
                {dataOld?.images?.map((e) => (
                  <Col key={e.id} md={4} sm={6} className="relative">
                    {deleteImgs && (
                      <input
                        type="checkbox"
                        value={e.id}
                        name="images_to_delete[]"
                        onChange={handleCheckedArray}
                        className="absolute -top-2 left-1 h-5 w-5"
                      />
                    )}
                    <div className="w-full h-[200px] bg-slate-100 rounded-md p-2">
                      <img
                        src={fileUrl + e.image}
                        alt=""
                        className="w-full h-full object-contain rounded-2xl"
                      />
                    </div>
                  </Col>
                ))}
              </Row>
            </div>
          )}
        </Col>
        {loading ? <Loading /> : ""}
      </Row>
      <Back onClick={handleSubmitMain} name="Save" />
    </div>
  );
};

export default EditOurBusiness;
