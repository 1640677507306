import JoditEditor from "jodit-react";
import { useContactUs, useFETCH, usePOST } from "../../APIs/useMyAPI";
import Loading from "../../Tools/Loading";
import { Back, Title } from "../../components";
import { useEffect, useState } from "react";

const Impressum = () => {
  const { data, isLoading } = useFETCH(`info?local=none`);
  const [impressum, setImpressum] = useState({
    en_impressum: "",
    ar_impressum: "",
  });
  useEffect(() => {
    setImpressum({
      en_impressum: data?.data.data.en_impressum,
      ar_impressum: data?.data.data.ar_impressum,
    });
  }, [data?.data.data]);
  const { loading, updateContactUs } = useContactUs("info");
  return (
    <>
      <Title title="Impressum" />
      {isLoading || loading ? <Loading /> : ""}
      <div className="text-xl font-bold text-center py-3">ألماني</div>
      <div>
        <JoditEditor
          value={impressum.en_impressum}
          onChange={(newContent) =>
            setImpressum({ ...impressum, en_impressum: newContent })
          }
        />

        <br />
        <div className="text-xl font-bold text-center py-3">عربي</div>
        <JoditEditor
          value={impressum.ar_impressum}
          onChange={(newContent) =>
            setImpressum({ ...impressum, ar_impressum: newContent })
          }
        />
      </div>
      <Back
        onClick={() =>
          updateContactUs(
            "impressum",
            impressum.ar_impressum,
            impressum.en_impressum
          )
        }
        name="Save"
      />
    </>
  );
};

export default Impressum;
